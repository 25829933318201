import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { isBrowser, isEDMode } from "./utils"

import { dashedFocus } from "@styles/ui"

// Assets
import openIcon from "@images/ih/shared/icon-accordion-open-red.svg"
import closeIcon from "@images/ih/shared/icon-accordion-close-red.svg"

const AccordionStudyResult = ({ heading, content }) => {
  const accordionRef = useRef()
  const [open, setOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    // Screenshots
    if (isBrowser) {
      if (isEDMode && window.location.search.indexOf("accordion-expand") > -1) {
        accordionRef.current && accordionRef.current[setOpen(!open)]
      }
    }
  }, [])

  useEffect(() => {
    if (open) {
      setContentHeight("auto")
    } else {
      setContentHeight(0)
    }
  }, [open])

  const gradientBefore = tw`before:(absolute right-0 left-0 top-0 bottom-0 m-[2px] bg-white rounded-[inherit])`
  const borderGradient = tw`gradient29`

  return (
    <div ref={accordionRef}>
      <button
        className={
          "xw-studyresult__toggle group" + (open ? " expanded" : " collapsed")
        }
        onClick={() => setOpen(!open)}
        css={[
          borderGradient,
          gradientBefore,
          tw`relative z-10 w-full rounded-[30px] p-[2px] transition-main`,
          open && tw`rounded-br-[0px] rounded-bl-[0px]`,
          tw`lg:(hover:[background: #7147bd])`,
          dashedFocus,
        ]}
      >
        <div
          tw="relative z-20 flex items-center justify-between bg-accordionFill rounded-[inherit] px-5 py-5
          md:(py-3)
          xl:(pl-10 pr-4)"
        >
          <h3
            css={[
              tw`text-xl font-extrabold text-left font-black w-4/5 md:(mr-[15px] w-auto)`,
            ]}
          >
            {heading}
          </h3>
          <img
            css={[tw`w-[44px] h-[44px]`, tw`group-hover:opacity-60`]}
            src={open ? closeIcon : openIcon}
            alt="dropdown button icon"
          />
        </div>
      </button>
      <div
        style={{
          height: contentHeight,
        }}
        css={[
          gradientBefore,
          borderGradient,
          tw`relative z-10 transition-main overflow-hidden bg-white before:(mt-0)`,
        ]}
      >
        <div css={[tw`relative z-20`]}>{content}</div>
      </div>
    </div>
  )
}

export default AccordionStudyResult

AccordionStudyResult.propTypes = {
  // heading: PropTypes.string,
  content: PropTypes.object.isRequired,
}

AccordionStudyResult.defaultProps = {
  heading: "",
}
