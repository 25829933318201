import React from "react"
import tw from "twin.macro"

import PrimaryCta from "@components/PrimaryCta"

const KeyPointsIh = ({ points }) => {
  return (
    <div
      css={[
        tw`flex flex-col w-full md:(flex-row justify-center gap-8 flex-wrap) xl:(gap-[30px] flex-nowrap)`,
      ]}
    >
      {points &&
        points.map((point, i) => {
          return (
            <div
              key={i}
              css={[
                tw`w-full mx-auto mb-7 border-2 border-dustyBlue bg-red-100 rounded-[30px] last:mb-0 sm:w-auto md:(mx-[unset] mb-0)`,
                points.length === 2 && tw`md:(grow max-w-[calc(50% - 2rem)])`,
              ]}
            >
              <div
                css={[
                  tw`flex flex-col justify-between relative w-full h-full bg-white rounded-[30px] text-center py-8 px-7 text-sm sm:(w-[340px]) xl:(w-[370px] min-h-[340px])`,
                  points.length === 2 && tw`md:w-full`,
                ]}
              >
                {/* Top content */}
                <div>
                  {point.title && (
                    <h3 tw="font-bold mb-3.5 text-xl">{point.title}</h3>
                  )}
                  {point.image && (
                    <div
                      css={[
                        tw`w-[60%] max-w-[80px] mx-auto overflow-hidden mb-[15px] mt-0 xl:(mb-6 max-w-[80px])`,
                      ]}
                    >
                      <img
                        tw="w-full block h-auto"
                        src={point.image}
                        alt={point.imageAlt}
                      />
                    </div>
                  )}
                  <div
                    css={[
                      tw`text-base font-normal leading-[25.2px] xl:(text-xl)`,
                      point.textBold && tw`font-bold`,
                    ]}
                  >
                    {point.text || point.textBold}
                  </div>
                </div>

                {/* Bottom cta */}
                {point.cta && (
                  <div tw="mt-4 xl:mt-5">
                    <PrimaryCta
                      isHollow
                      normalCase
                      url={point.cta.url}
                      addedStyles={tw`w-[13rem]`}
                    >
                      {"See Results Below"}
                    </PrimaryCta>
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default KeyPointsIh
